import { useEffect, useState } from "react";
import ALink from "~/components/features/alink";
import Axios from 'axios';
import { getUserData } from "~/utils";

let Notifications = ({ language, lang }) => {
    const [notifys, setNotifys] = useState([]);
    const user = getUserData();
    const notifyIcons = { 1: 'notify-order.svg', 2: 'messages.svg', 3: 'campaign.svg', 4: 'announce.svg' };


    let getNotifications = async () => {
        await Axios({
            method: "post",
            responseType: "json",
            url: "api/notifications/",
            headers: {
                "Authorization": (user.token ?? null)
            },
            data: {
                lang: lang,
                action: 'getNotifications',
                page: 1,
                userToken: window.localStorage.getItem('userToken')
            }
        }).then(({ data }) => {
            setNotifys(data.notifications);
        });
    }

    let markAsReadAll = async () => {
        await Axios({
            method: "post",
            responseType: "json",
            url: "api/notifications/",
            headers: {
                "Authorization": (user.token ?? null)
            },
            data: {
                lang: lang,
                action: 'markAllAsRead',
                userToken: window.localStorage.getItem('userToken')
            }
        }).then(({ data }) => {
            if (data.status == 1) {
                document.querySelectorAll('.notification-item').forEach((ni) =>
                    ni.classList.remove('unread')
                );
                document.querySelector('.notification').innerHTML = 0;
                document.querySelector('.notifications .cart-count').remove()
            }
        });
    }


    useEffect(() => {
        if (user.token) {
            getNotifications();
        }
    }, [])
    return (
        <div className="notifications dropdown cart-dropdown">
            {
                notifys.length > 0 &&
                <>
                    <ALink href="/my-account/notifications" className="dropdown-toggle" style={{ marginInlineStart: "0", overFlow: "initial" }} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
                        <div className="icon position-relative">
                            <img src='/images/icons/notification.svg' width={22} alt='notification' />
                            {
                                notifys.filter((n) => { return n.readed == 0 }).length > 0 &&
                                <span className="cart-count notification">{notifys.filter((n) => { return n.readed == 0 }).length}</span>
                            }
                        </div>
                    </ALink>
                    <div className={`dropdown-menu dropdown-menu-right cart-dropdown`} >
                        <div className="notifications-header">
                            {language.notifications}
                        </div>
                        <div className="notification-items">
                            {

                                notifys.map((x, y) =>
                                    <ALink href={x.action}>
                                        <div className={`notification-item ${x.readed == 0 ? 'unread' : ''}`} key={y}>
                                            <div className="notification-item-content">
                                                <div className="notification-item-content-text">
                                                    {
                                                        x.type == 1 ?
                                                            x.subject : x.message
                                                    }
                                                </div>
                                                <div className="notification-item-content-date">
                                                    {x.date}
                                                </div>
                                            </div>
                                            <div className="notification-item-icon">
                                                <img src={`/images/icons/${notifyIcons[x.type]}`} width={32} alt={notifyIcons[x.type]} />
                                            </div>
                                        </div>
                                    </ALink>
                                )
                            }
                        </div>
                        <div className="notification-footer">
                            <span onClick={markAsReadAll}>{language.markAsReadAll}</span>
                            <ALink href="/my-account/notifications">{language.seeAll}</ALink>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default Notifications;