import React from 'react';
import { connect } from 'react-redux';
import ALink from '~/components/features/alink';

function WishlistMenu(props) {
    const { wishlist, user } = props;

    return (
        <ALink href={user.token ? "/my-account/my-favorites" : "/wishlist"} title="Wishlist" className="wishlist-link">
            <div className="icon position-relative wishlist-icon p-2">
                <img src='/images/icons/wishlist.svg' width={28} alt='wishlist' />
                <span className="wishlist-count">{wishlist.length}</span>
            </div>
        </ALink>
    );
}

function mapStateToProps(state) {
    return {
        wishlist: state.wishlist.data
    }
}

export default connect(mapStateToProps, {})(WishlistMenu);