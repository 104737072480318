import { useContext, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

import Header from "./partials/header/header";
import Footer from "./partials/footer/footer";

import { actions } from '../store/other';
import { isSafariBrowser, isEdgeBrowser } from "~/utils";
import { LangContext } from "~/context/lang";
import Axios from "axios";
import QuickviewModal from "./features/modals/quickview-modal";
import MobileMenu from "./features/mobile-menu";

function Layout({ children }) {
    let { lang } = useContext(LangContext);
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState([]);

    let scrollTop;
    useEffect(() => {
        scrollTop = document.querySelector('#scroll-top');
        window.addEventListener('scroll', scrollHandler, false);
    }, [])

    function toScrollTop() {
        if (isSafariBrowser() || isEdgeBrowser()) {
            let pos = window.pageYOffset;
            let timerId = setInterval(() => {
                if (pos <= 0) clearInterval(timerId);
                window.scrollBy(0, -120);
                pos -= 120;
            }, 1);
        } else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    function scrollHandler() {
        if (window.pageYOffset >= 400) {
            scrollTop.classList.add('show');
        } else {
            scrollTop.classList.remove('show');
        }
    }

    // const outOfHeaders = ["/login/", "/register/", "/forgot/", "/login/?redirectUrl=/checkout"];

    function hideMobileMenu() {
        document.querySelector('body').classList.remove('mmenu-active');
    }


    function getContent() {
        setLoading(false);
        Axios({
            method: 'post',
            url: 'api/settings/',
            responseType: 'json',
            data: {
                lang: lang
            }
        }).then(({ data }) => {
            setContent(data);
            setLoading(true);
            localStorage.setItem('tax', data[0].taxRatio);
            localStorage.setItem('loginSettings', JSON.stringify(data[4]));
            localStorage.setItem('popupBanner', JSON.stringify(data[5]));
            localStorage.setItem('contactInfo', JSON.stringify(data[0]));
            window.localStorage.setItem('headlines', JSON.stringify(data[7]));
        })
    }

    useEffect(() => {
        getContent();
    }, [lang])

    return (
        <>
            <div className="page-wrapper">
                {
                    loading &&
                    <>
                        <Header adClass="position-relative" content={content} />
                        {children}
                        <Footer content={content} />
                    </>
                }
            </div>
            <div className="mobile-menu-overlay" onClick={hideMobileMenu}></div>

            <button id="scroll-top" title="Back to top" onClick={toScrollTop}>
                <i className="icon-arrow-up"></i>
            </button>
            <MobileMenu />

            <ToastContainer
                autoClose={3000}
                duration={300}
                newestOnTo={true}
                className="toast-container"
                position="top-center"
                closeButton={false}
                hideProgressBar={true}
                newestOnTop={true}
                draggable={false}
                theme={"colored"}
                rtl={lang == 'ar'}
            />

            <QuickviewModal />

        </>
    )
}

export default connect(null, { ...actions })(Layout);