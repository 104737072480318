import { useRouter } from "next/router";

/**
 * get number of products in cart
 * @param { Array } cartItems
 * @return { Integer } numbers of cart items in cartlist
 */
export const cartQtyTotal = (cartItems) => {
	return cartItems.reduce((acc, cur) => {
		return acc + parseInt(cur.qty, 10);
	}, 0);
};

/**
 * utils to for second to time
 */
export function secondsToTime(secs) {
	let divisor_for_minutes = secs % (60 * 60);
	let minutes = Math.floor(divisor_for_minutes / 60);

	let divisor_for_seconds = divisor_for_minutes % 60;
	let seconds = Math.ceil(divisor_for_seconds);

	return minutes + ":" + seconds;
}

/**
 *
 * @param {Array} wishlist
 * @param {Object} product
 */
export const isInWishlist = (wishlist, product) => {
	return (
		product &&
		wishlist.findIndex((item) => item.id == product.id) > -1
	);
};

/**
 *
 * @param {Array} compare
 * @param {Object} product
 */
export const isInCompare = (compare, product) => {
	return (
		product &&
		compare.findIndex((item) => item.id == product.id) > -1
	);
};

/**
 * utils to detect safari browser
 * @return {bool}
 */
export const isSafariBrowser = function () {
	let sUsrAg = navigator.userAgent;
	if (sUsrAg.indexOf("Safari") !== -1 && sUsrAg.indexOf("Chrome") === -1)
		return true;
	return false;
};

/**
 * utils to detect Edge browser
 * @return {bool}
 */
export const isEdgeBrowser = function () {
	let sUsrAg = navigator.userAgent;
	if (sUsrAg.indexOf("Edge") > -1) return true;
	return false;
};

/**
 * get index of the element
 * @param {Element} element
 */
export const getIndex = function (element) {
	let children = element.parentElement.children;
	for (let i = 0; i < children.length; i++) {
		if (element == children[i]) return i;
	}
	return 0;
};

/**
 * Scrolling to Page content section
 */
export const scrollToPageContent = function () {
	let to = document.querySelector(".page-content").offsetTop - 74;
	if (isSafariBrowser() || isEdgeBrowser()) {
		let pos = window.pageYOffset;
		let timerId = setInterval(() => {
			if (pos <= to) clearInterval(timerId);
			else {
				window.scrollBy(0, -120);
				pos -= 120;
			}
		}, 1);
	} else {
		window.scrollTo({
			top: to,
			behavior: "smooth",
		});
	}
};

/**
 * Prevent Xss Attack
 * @param {Node} html
 */
export function safeContent(html) {
	const SCRIPT_REGEX =
		/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;

	// Removing the <script> tags
	while (SCRIPT_REGEX.test(html)) {
		html = html.replace(SCRIPT_REGEX, "");
	}

	// Removing all events from tags...
	html = html.replace(/ on\w+="[^"]*"/g, "");

	return {
		__html: html,
	};
}

/**
 * remove space and convert to lowercase
 */
export function removeSpaceToLowercase(str) {
	if (str) {
		return str.toLowerCase().replaceAll(" ", "");
	}
}

/**
 * uppercase first letters
 */
export function capitalize(str) {
	if (str) {
		const lower = str.toLowerCase();
		return str.charAt(0).toUpperCase() + lower.slice(1);
	}
}

/**
 * copy to clipboard text
 **/

export function copyToClipboard(text) {
	let status = 9;
	let copy = navigator.clipboard.writeText(text);
	if (copy) {
		status = 1;
	}
	return status;
}

export function seePassword() {
	let passwordType = document.querySelectorAll(".password");
	passwordType.forEach((x) => {
		return (x.type = x.type == "password" ? "text" : "password");
	});

	let passwordSeeImage = document.querySelectorAll(".passwordSeeImage");
	passwordSeeImage.forEach((x) => {
		x.src =
			passwordType[0].type == "password"
				? "/images/icons/eye.svg"
				: "/images/icons/eye-line.svg";
	});
}

export function NextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div className={className} onClick={onClick}>
			<img
				src={"/images/icons/slider-right.svg"}
				alt="slider-right"
			/>
		</div>
	);
}

export function PrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div className={className} onClick={onClick}>
			<img
				src={"/images/icons/slider-left.svg"}
				alt="slider-left"
			/>
		</div>
	);
}

export function goBottom() {
	let messageLists = document?.querySelector(".support-messages-list");
	if (messageLists) {
		messageLists.scrollTo({
			top: messageLists.scrollHeight,
			behavior: "smooth",
		});
	}
}

export function groupMessagesByDate(messages) {
	const groupedMessages = {};

	messages.forEach((message) => {
		const date = message.indate.split(" ")[0]; // Tarihi al

		if (!groupedMessages[date]) {
			groupedMessages[date] = [];
		}

		groupedMessages[date].push(message);
	});

	return groupedMessages;
}

export function addZero(number) {
	if (number < 10) {
		return "0" + number;
	} else {
		return number;
	}
}

export function findExtension(fileName) {
	return fileName.split(".")[1];
}

function SliderNextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div className={className} style={{ ...style }} onClick={onClick}>
			<img
				src="/images/icons/product-slider-right-arrow.png"
				alt="down-arrow"
			/>
		</div>
	);
}

function SliderPrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div className={className} style={{ ...style }} onClick={onClick}>
			<img
				src="/images/icons/product-slider-left-arrow.png"
				alt="down-arrow"
			/>
		</div>
	);
}

export var homeSliderSettings = {
	dots: false,
	infinite: true,
	autoPlay: true,
	speed: 500,
	slidesToShow: 6,
	slidesToScroll: 1,
	nextArrow: <SliderNextArrow />,
	prevArrow: <SliderPrevArrow />,
	responsive: [
		{
			breakpoint: 1224,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 2,
				infinite: true,
				centerMode: true,
				dots: true,
			},
		},
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 5,
				slidesToScroll: 5,
				infinite: true,
				centerMode: true,
				dots: true,
			},
		},
		{
			breakpoint: 900,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
				infinite: true,
				centerMode: true,
				dots: true,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
				initialSlide: 1,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
			},
		},
	],
};

export var shortHomeSliderSettings = {
	dots: false,
	infinite: true,
	autoPlay: true,
	speed: 500,
	slidesToShow: 3,
	slidesToScroll: 1,
	nextArrow: <SliderNextArrow />,
	prevArrow: <SliderPrevArrow />,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 5,
				slidesToScroll: 5,
				infinite: true,
				centerMode: true,
				dots: true,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
				initialSlide: 1,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
			},
		},
	],
};

export var partnerSliderSettings = {
	dots: false,
	centerMode: true,
	infinite: true,
	autoPlay: true,
	speed: 500,
	slidesToShow: 5,
	slidesToScroll: 1,
	nextArrow: <SliderNextArrow />,
	prevArrow: <SliderPrevArrow />,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				infinite: true,
				centerMode: true,
				dots: true,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				initialSlide: 2,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
};

export function toggleSidebar() {
	if (
		document
			.querySelector("body")
			.classList.contains("sidebar-filter-active")
	) {
		document
			.querySelector("body")
			.classList.remove("sidebar-filter-active");
	} else {
		document
			.querySelector("body")
			.classList.add("sidebar-filter-active");
	}
}

export function hideSidebar() {
	document.querySelector("body").classList.remove("sidebar-filter-active");
}

export function onSortByChange(e) {
	const router = useRouter();
	let queryObject = router.query;
	let url = router.pathname.replace("[type]", slug) + "?";
	for (let key in queryObject) {
		if (key !== "type" && key !== "sortBy") {
			url += key + "=" + queryObject[key] + "&";
		}
	}
	router.push(url + "sortBy=" + e.value);
}

export function resizeHandle(setToggle) {
	if (document.querySelector("body").offsetWidth < 992) setToggle(true);
	else setToggle(false);
}

export function getUserData(loginRequired = false) {
	let getUserData = window.localStorage.getItem("userData");
	let user = [];
	if (getUserData) {
		user = JSON.parse(getUserData);
	} else {
		if (loginRequired) {
			window.location.href = "/login";
		}
	}
	return user;
}

export function getSortMenuData(language) {
	return [
		{ value: "", label: language.select },
		{ value: "newest", label: language.newest },
		{ value: "popularity", label: language.mostPopular },
		{ value: "highPrice", label: language.highPrice },
		{ value: "lowPrice", label: language.lowPrice },
		{ value: "rating", label: language.mostRate },
		{ value: "oldest", label: language.oldest },
	];
}

export function productQueries(query) {
	let minPrice = query.minPrice ? query.minPrice : 0;
	let maxPrice = query.maxPrice ? query.maxPrice : 0;
	let page = query.page ? query.page : 0;
	let sort = query.sortBy ? query.sortBy : "";
	let search = query.searchKey ? query.searchKey : "";
	let filterNotInclude = [
		"slug",
		"searchKey",
		"maxPrice",
		"minPrice",
		"sort",
		"page",
		"tag",
		"brand",
	];
	const filter = Object.fromEntries(
		Object.entries(query).filter(
			([key]) => !filterNotInclude.includes(key)
		)
	);
	return { minPrice, maxPrice, page, search, sort, filter };
}

export function getAttributeGroups(productData) {
	const attr = [];
	const attributeGroups = [];
	productData.map((x) => {
		x.attributes.length > 0 &&
			x.attributes.map((k) => {
				attr.push(k);
			});
	});
	attr.map((t) => {
		if (
			attributeGroups.filter(
				(e) => e.attr_group === t.attr_group
			).length == 0
		) {
			attributeGroups.push({
				attr_group: t.attr_group,
				attr_group_icon: t.attr_group_icon,
				attr_group_name: t.attr_group_name,
				attr_value: [
					...new Map(
						attr
							.filter(
								(f) =>
									f.attr_group ===
									t.attr_group
							)
							.map((item) => [
								item["attr_val_id"],
								item,
							])
					).values(),
				],
			});
		}
	});
	return attributeGroups;
}
