import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import Axios from 'axios';
import { wrapper } from "../store/index.js";
import Layout from '../components/layout';

import "~/public/scss/style.scss";
import LangProvider from "~/context/lang";
import AuthProvider from "~/context/auth";

Axios.defaults.baseURL = process.env.API_URL;

const WrappedApp = ({ Component, ...rest }) => {
    const { store, props } = wrapper.useWrappedStore(rest);
    const { pageProps } = props;
    return (
        <Provider store={store}>
            <LangProvider>
                <AuthProvider>
                    <PersistGate
                        persistor={store.__persistor}
                        loading={
                            <div className="loading-overlay">
                                <div className="bounce-loader">
                                    <div className="bounce1"></div>
                                    <div className="bounce2"></div>
                                    <div className="bounce3"></div>
                                </div>
                            </div>
                        }>
                        <Layout>
                            <Component {...pageProps} />
                        </Layout>
                    </PersistGate>
                </AuthProvider>
            </LangProvider>
        </Provider >
    )
};

export default WrappedApp;
