import React, { useState, useEffect } from 'react'

export let LangContext = React.createContext();

let LangProvider = ({ children }) => {
  let [lang, setLang] = useState('ar');
  let [currency, setCurrency] = useState(JSON.stringify({
    "currency": "SAR",
    "currency_symbol": "SAR",
    "position": "right"
}));

  let langs = {
    en: () => require('./lang/en.json'),
    ar: () => require('./lang/ar.json')
  };

  let getLang = (page) => langs[lang]()[page];

  useEffect(() => {
    let local = localStorage.getItem('lang');
    let cur = localStorage.getItem('currency');

    if (local) {
      setLang(local);
    }

    if (cur) {
      setCurrency(cur)
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('lang', lang);
    localStorage.setItem('currency', currency);

    if (lang === 'ar') {
      document.body.classList.remove('ltr');
      document.body.classList.add('rtl');
      document.body.dir = 'rtl';
    } else {
      document.body.classList.remove('rtl');
      document.body.classList.add('ltr');
      document.body.dir = 'ltr';
    }

  }, [lang, currency]);
  return (
    <LangContext.Provider
      value={{
        getLang,
        setLang,
        lang,
        currency,
        setCurrency,
      }}
    >
      {children}
    </LangContext.Provider>
  )
}
export default LangProvider;
