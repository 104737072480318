import React, { useContext, useEffect } from 'react';

// Import Custom Component
import ALink from '~/components/features/alink';
import CartMenu from '~/components/partials/header/partials/cart-menu';
import MainMenu from '~/components/partials/header/partials/main-menu';
import { LangContext } from '~/context/lang';
import ProfileMenu from './partials/profile-menu';
import { isMobile } from 'react-device-detect';
import { AuthContext } from '~/context/auth';
import Head from 'next/head';
import Axios from 'axios';
import { getUserData } from '~/utils';
import SearchMenu from './partials/search-menu';
import WishlistMenu from './partials/wishlist-menu';
import Notifications from './partials/notification-menu';
import Marquee from 'react-fast-marquee';

function Header(props) {
    let { adClass, content } = props;
    let { getLang, setLang, lang, currency } = useContext(LangContext);
    let { logged, logout, directLogout } = useContext(AuthContext);
    let language = getLang("header");

    const currencies = JSON.parse(currency);
    const user = getUserData();
    const languages = [
        {
            short: "ar",
            text: "العربية"
        },
        {
            short: "en",
            text: "English"
        }
    ];

    class adjustSDKService {
        async initializeAdjust() {
            if (!this.adjust) {
                this.adjust = require("@adjustcom/adjust-web-sdk");
                await this.adjust.initSdk({
                    appToken: "3r6n84enxda8",
                    environment: 'sandbox'
                });
            }
        }
    }

    useEffect(() => {
        if (content[2].adjust_token !== "") {
            if (typeof window !== "undefined") {
                // the adjustSDKService is a class I implemented which exports the instance of the class.
                // I triggered here the initializeAdjust method from my class
                const adjust = new adjustSDKService();
                adjust.initializeAdjust();
            }
        }
    }, [content])


    let checkUserAuth = async (user) => {
        await Axios({
            method: 'post',
            url: `api/user/`,
            responseType: "json",
            headers: {
                'Authorization': (user.token ? user.token : null)
            },
            data: {
                lang: lang,
                action: 'checkLogin'
            }
        }).then(({ data }) => {
            if (data.status === 8) {
                directLogout(false);
            }
        })
    }

    let menusMobileFix = "";
    if (isMobile) {
        menusMobileFix = {
            position: 'absolute',
            top: '20%',
            transform: 'translateY(-20%)'
        }
    }

    function openMobileMenu() {
        document.querySelector('body').classList.add('mmenu-active');
    }

    useEffect(() => {
        if (user?.status == 1) {
            checkUserAuth(user);
        }
    }, [user])

    return (
        <header className={`header header-custom ${adClass}`}>
            {
                isMobile ?
                    <div className="header-top fw-normal">

                        <div className="container">
                            <div className="header-left">
                                <div className="header-dropdown d-flex align-items-center">
                                    {

                                        logged ?
                                            <div className="dropdown profile-dropdown">
                                                <button className="dropdown-toggle" title={user?.userName}>
                                                    <div className='cart-txt'>{user?.userName}</div>
                                                </button>
                                                <div className="dropdown-menu p-0 mobile-profile-menu">
                                                    <ul className="profile-menu">
                                                        <ALink href={`/my-account/profile/`}>
                                                            <li>
                                                                {language.profile}
                                                            </li>
                                                        </ALink>
                                                        <ALink href={`/my-account/change-password/`}>
                                                            <li>
                                                                {language.changePassword}
                                                            </li>
                                                        </ALink>
                                                        <ALink href={`/my-account/my-orders/`}>
                                                            <li>
                                                                {language.myOrders}
                                                            </li>
                                                        </ALink>
                                                        <ALink href={`/my-account/my-address/`}>
                                                            <li>
                                                                {language.myAddress}
                                                            </li>
                                                        </ALink>
                                                        <ALink href={`/my-account/my-address/`}>
                                                            <li>
                                                                {language.myAddress}
                                                            </li>
                                                        </ALink>
                                                        <ALink href={`/wishlist/`}>
                                                            <li>
                                                                {language.wishlist}
                                                            </li>
                                                        </ALink>
                                                        <ALink href={`/my-account/help/`}>
                                                            <li>
                                                                {language.help}
                                                            </li>
                                                        </ALink>
                                                        <ALink href={`#`}>
                                                            <li onClick={logout}>
                                                                {language.signOut}
                                                            </li>
                                                        </ALink>
                                                    </ul>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <ALink href="/login/" className='mx-4'>{language.login}</ALink>
                                            </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}
            <div className="header-top-bar">
                <div className="container">
                    <div className="header-top-bar-content">
                        <Marquee speed={isMobile ? 200 : 150}>
                            {
                                content[7].filter((p) => { return p.position == 'top' }).map((x, y) =>
                                    <div className={`free-delivery mx-${isMobile ? '7' : '16'}`} key={y}>
                                        {x.headline}
                                    </div>
                                )
                            }
                        </Marquee>
                    </div>
                </div>
            </div>
            <div className="header-middle sticky-header">
                <div className="container">
                    <div className="row w-100 align-items-center">
                        <div className="col-lg-2 col-sm-12">
                            <div className="header-left">
                                <MainMenu language={language} lang={lang} />
                                <button className="mobile-menu-toggler" onClick={openMobileMenu}>
                                    <i className="icon-bars"></i>
                                </button>
                                <ALink href="/" className="logo m-auto">
                                    <img src="images/logo.svg" width={110} alt="mkhazin Logo" />
                                </ALink>
                            </div>
                        </div>
                        <div className={`col-lg-5 col-sm-12 ${isMobile ? 'g-0 me-2' : ''}`}>
                            <div className="header-center">
                                <SearchMenu language={language} lang={lang} />
                            </div>
                        </div>
                        <div className="col-lg-5 col-sm-12" style={{ ...menusMobileFix }}>
                            <div className="header-right justify-content-end">
                                <div className="location-info">
                                    {
                                        !isMobile &&
                                        Array(user).length > 0 &&
                                        <>
                                            <img src='/images/icons/map-pin.svg' width={26} alt='map-pin' />
                                            <ALink href="/my-account/my-address/" style={{ width: "9rem" }}>
                                                {user?.address}
                                            </ALink>
                                        </>
                                    }
                                    {
                                        !isMobile &&
                                        <ProfileMenu language={language} lang={lang} />
                                    }
                                </div>
                                <div className="dropdown d-flex align-items-center">

                                    <div className={`header-dropdown p-0 currency-menu ${isMobile ? '' : 'mx-3'}`}>
                                        <a href="#"><img className="mx-2" src={`images/${lang}_icon.jpg`} width="20" style={{ minWidth: "2rem" }} alt={`${lang}_icon"`} /></a>
                                        <div className="header-menu mt-1">
                                            <ul>
                                                {
                                                    languages.filter((x) => { return x.short !== lang }).map((l, k) =>
                                                        <li key={k}>
                                                            <a href="#" onClick={e => { e.preventDefault(); setLang(l.short) }}><img className="mx-2" src={`images/${l.short}_icon.jpg`} width="20" style={{ minWidth: "2rem" }} alt={`${l.short}_icon"`} /></a>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    {
                                        !isMobile &&
                                        <>
                                            <Notifications language={language} lang={lang} />
                                            <WishlistMenu language={language} lang={lang} currency={currencies} user={user} />
                                        </>
                                    }
                                    <CartMenu language={language} lang={lang} currency={currencies} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Head>
                {
                    content[2].seo_title != "" ?
                        <meta name="Title" content={content[2].seo_title} />
                        :
                        ''
                }
                {
                    content[2].seo_description != "" ?
                        <meta name="description" content={content[2].seo_description} />
                        :
                        ''
                }
                {
                    content[2].google_analytics != "" ?
                        <>
                            <script
                                async
                                src={`https://www.googletagmanager.com/gtag/js?id=${content[2].google_analytics}`}
                            />
                            <script
                                dangerouslySetInnerHTML={{
                                    __html: `
                                        window.dataLayer = window.dataLayer || [];
                                        function gtag(){dataLayer.push(arguments);}
                                        gtag('js', new Date());
                                        gtag('config', '${content[2].google_analytics}', {
                                            page_path: window.location.pathname,
                                        });
                                    `,
                                }}
                            />
                        </>
                        : ''
                }
                {
                    content[2].google_tag_manager != "" ?
                        <script
                            dangerouslySetInnerHTML={{
                                __html:
                                    `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                        })(window,document,'script','dataLayer','${content[2].google_tag_manager}');
                                    `
                            }}
                        />
                        :
                        ''
                }

                {
                    content[2].chat_script != "" ?
                        <script
                            dangerouslySetInnerHTML={{
                                __html:
                                    `${content[2].chat_script}`
                            }}
                        />
                        :
                        ''
                }
            </Head>
        </header>

    )
}



export default Header;
