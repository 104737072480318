import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';

export const actionTypes = {
    addToWishlist: 'ADD_TO_WISHLIST',
    removeFromWishlist: 'REMOVE_FROM_WISHLIST',
    refreshStore: 'REFRESH_STORE',
}
const initialState = {
    data: [],
}

const wishlistReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.addToWishlist:
            var findIndex = state.data.findIndex(item => item.id === action.payload.product.id);
            if (findIndex == -1) {
                return {
                    data: [
                        ...state.data,
                        action.payload.product
                    ]
                };
            }


        case actionTypes.removeFromWishlist:
            return {
                data: state.data.filter(item => item.id !== action.payload.product.id)
            };

        case actionTypes.refreshStore:
            return initialState;

        default:
            return state;
    }
}

export const actions = {
    addToWishlist: (product, message = true, lang) => ({
        type: actionTypes.addToWishlist,
        payload: {
            product,
            message,
            lang
        }
    }),

    removeFromWishlist: (product, lang) => ({
        type: actionTypes.removeFromWishlist,
        payload: {
            product,
            lang
        }
    })
}

export function* wishlistSaga() {
    yield takeEvery(actionTypes.addToWishlist, function* saga(e) {
        let lang = e.payload.lang;
        if (e.payload.message) {
            if (lang == 'ar') {
                toast.success("تمت إضافة المنتج إلى قائمة المفضلة");
            } else if (lang == 'tr') {
                toast.success("Ürün favori listesine ekledi");
            } else {
                toast.success("Product added to favorite list");
            }
        }
    })

    yield takeEvery(actionTypes.removeFromWishlist, function* saga(e) {
        let lang = e.payload.lang;
        if (lang == 'ar') {
            toast.success("تمت إزالة المنتج من المفضلة");
        } else if (lang == 'tr') {
            toast.success("Ürün favorilerden kaldırıldı");
        } else {
            toast.success("Product removed from favorites");
        }
    })
}

const persistConfig = {
    keyPrefix: "mkhazin-",
    key: 'wishlist',
    storage,
}

export default persistReducer(persistConfig, wishlistReducer);