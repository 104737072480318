import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

export const actionTypes = {
    showQuick: 'SHOW_QUICKVIEW',
    hideQuick: 'HIDE_QUICKVIEW',
    refreshStore: 'REFRESH_STORE'
};

let initialState = {
    quickShow: false,
    data: [],
};
const otherReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.showQuick:
            return {
                ...state,
                data: action.payload.data,
                quickShow: true,
            }

        case actionTypes.hideQuick:
            return {
                ...state,
                quickShow: false,
            }
        case actionTypes.refreshStore:
            return {
                current: action.payload.current,
                quickShow: false,
                tryItNow: false,
            };

        default:
            return state;
    }
}

export const actions = {
    refreshStore: (current) => ({
        type: actionTypes.refreshStore,
        payload: {
            current: current
        }
    }),

    showQuickView: product => ({
        type: actionTypes.showQuick,
        payload: {
            data: product
        }
    }),

    hideQuick: () => ({
        type: actionTypes.hideQuick,
    }),
}

const persistConfig = {
    keyPrefix: "mkhazin-",
    key: "other",
    storage
}

export default persistReducer(persistConfig, otherReducer);