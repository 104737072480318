import Modal from 'react-modal';
import { connect } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useRouter } from 'next/router';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NextArrow, PrevArrow, getUserData } from '~/utils';

import DetailOne from '~/components/partials/product/details/detail';
import { LangContext } from '~/context/lang';

import { actions as otherAction } from '~/store/other';
import React, { useState, useEffect, useContext } from 'react';
import Gallery from '~/components/partials/product/gallery/gallery';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(51,51,51,0.6)',
        zIndex: '9000'
    }
};

Modal.setAppElement('body');

function QuickViewModal(props) {
    const { product } = props;
    let { getLang } = useContext(LangContext);
    let language = getLang("product");
    const user = getUserData();

    if (!product) {
        return <div></div>
    }
    const router = useRouter();
    const [carouselRef, setCarouselRef] = useState(null);

    const events = {
        onTranslate: function (e) {
            let items = document.querySelectorAll('.quickView-content .product-gallery-item');
            document.querySelector('.quickView-content .product-gallery-item.active').classList.remove('active');
            items[e.item.index].classList.add('active');
        }
    }

    useEffect(() => {
        router.events.on('routeChangeStart', closeModal);
        carouselRef && carouselRef.current && carouselRef.current.goTo(0);

        return () => {
            router.events.off('routeChangeStart', closeModal);
        }
    }, [])

    function closeModal() {
        if (document.querySelector(".ReactModal__Content")) {
            document.querySelector(".ReactModal__Content").classList.remove("ReactModal__Content--after-open");
        }

        if (document.querySelector(".ReactModal__Overlay")) {
            document.querySelector(".ReactModal__Overlay").style.opacity = '0';
        }

        setTimeout(() => {
            props.hideQuick();
        }, 250);
    }

    function changeBgImage(e, index) {
        document.querySelector('.quickView-content .product-gallery-item.active').classList.remove('active');
        e.currentTarget.classList.add('active');
        carouselRef.current.goTo(index);
    }

    var settings = {
        dots: false,
        infinite: false,
        autoPlay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    return (
        <>
            {
                product ?
                    <Modal
                        isOpen={props.modalShow}
                        onRequestClose={closeModal}
                        className="container quickView-container"
                        overlayClassName="d-flex align-items-center justify-content-center"
                        shouldReturnFocusAfterClose={false}
                        closeTimeoutMS={100}
                        contentLabel="QuickView"
                        style={customStyles}
                        id="product-quickview"
                    >
                        <div className="modal-content">
                            <div className="quickView-content horizontal skeleton-body">
                                <div className={`row skel-pro-single skel-quickview mb-0 loaded`}>
                                    <div className="col-lg-6 p-0 pe-lg-2 mb-2 mb-lg-0">
                                        <div className="skel-product-gallery"></div>
                                        {

                                            <>
                                                <div className="product-lg mb-1 position-relative">
                                                    {
                                                        product.new ?
                                                            <span className="product-label label-new">{language.new}</span>
                                                            : ""
                                                    }

                                                    {
                                                        product.sale_price ?
                                                            <span className="product-label label-sale">{language.sale}</span>
                                                            : ""
                                                    }

                                                    {
                                                        product.top ?
                                                            <span className="product-label label-top">{language.top}</span>
                                                            : ""
                                                    }

                                                    {
                                                        product.stock == 0 ?
                                                            <span className="product-label label-out">{language.outOfStock}</span>
                                                            : ""
                                                    }
                                                    <Gallery product={product} language={language} />
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="col-lg-6 quickview-desc ps-0 ps-lg-4 pe-0">
                                        <div className="entry-summary row">
                                            <div className="col-md-12">
                                                <div className="entry-summary1 mt-2 mt-md-0"></div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="entry-summary2"></div>
                                            </div>
                                        </div>
                                        {
                                            <DetailOne product={product} language={language} userData={user} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button title={language.close} type="button" className="mfp-close" onClick={closeModal}><span>×</span></button>
                    </Modal>
                    : ''
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        product: state.other.data,
        modalShow: state.other.quickShow,
    }
}

export default connect(mapStateToProps, { ...otherAction })(QuickViewModal);
