import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ALink from '~/components/features/alink';
import { actions } from '~/store/cart';
import { cartQtyTotal, getUserData } from '~/utils/index';
import Axios from 'axios';

function CartMenu(props) {
    const { cartlist, removeFromCart, language, lang, currency } = props;
    const [total, setTotal] = useState(0);
    const [cartMenu, setCartMenu] = useState([]);
    const user = getUserData();

    let getCart = async () => {
        var cartTotal = 0;
        var pointTotal = 0;
        await Axios({
            method: "post",
            responseType: "json",
            url: "api/basket/",
            headers: {
                "Authorization": (user.token ? user.token : null)
            },
            data: {
                lang: lang,
                action: 'getBasketDetails',
                guest: (user.guest ? 1 : 0),
                currency: currency.currency,
                userToken: window.localStorage.getItem('userToken')
            }
        }).then((cart) => {
            if (cart.data["basket"]) {
                setCartMenu(cart.data["basket"]);
                cart.data["basket"].map((x) => cartTotal += x.sum);
                pointTotal = cart.data["basket"][0]["points"];
            }
            setTotal(cartTotal - pointTotal);
        })
    }

    let removeFromCarts = async (productID, unit, branchId) => {
        await Axios({
            method: "post",
            responseType: "json",
            url: "api/basket/",
            headers: {
                "Authorization": (user.token ?? null)
            },
            data: {
                lang: lang,
                action: 'basketDel',
                productID: productID,
                unit,
                branchId,
                userToken: window.localStorage.getItem('userToken')
            }
        }).then((removeCart) => {
            if (removeCart.data.status === 1) {
                if (removeCart.data.refresh === 1) {
                    removeFromCart(productID, lang)
                    window.location.reload();
                } else {
                    getCart();
                    props.removeFromCart(productID, lang)
                }
            }
        });
    }

    useEffect(() => {
        getCart();
    }, [cartlist, removeFromCart, lang])

    return (
        <div className="dropdown cart-dropdown">
            <ALink href="/cart" className="dropdown-toggle" style={{ overflow: 'initial', marginInlineStart: "1rem" }} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
                <div className="icon position-relative">
                    <img src='/images/icons/cart.svg' width={26} alt='cart' />
                    <span className="cart-count">{cartQtyTotal(cartMenu)}</span>
                </div>
            </ALink>
            <div className={`dropdown-menu dropdown-menu-right cart-dropdown ${cartMenu.length === 0 ? 'text-center' : ''}`} >
                {
                    cartMenu.length === 0 ?
                        <p>{language.noProductsCart}</p>
                        :
                        <>
                            <div className="dropdown-cart-products">
                                {cartMenu.map((item, index) => (
                                    <div className="product" key={index}>
                                        <figure className="product-image-container">
                                            <ALink href={`/product/${item.url}`} className="product-image">
                                                <img src={process.env.IMG_URL + '/products/' + item.prodImg} alt="product" />
                                            </ALink>
                                        </figure>
                                        <div className="product-cart-details">
                                            <h4 className="product-title">
                                                <ALink href={`/product/${item.url}`}>{item.name}</ALink>
                                            </h4>
                                            <span className="cart-product-info">
                                                <div className="cart-product-info-price">
                                                    <span className="cart-product-qty">{item.qty}</span>
                                                    <span>x</span>{item.sale_price ? item.sale_price.toFixed(2) : item.price.toFixed(2)} <span>{currency.currency_symbol}</span>
                                                </div>
                                                <div className="cart-product-info-other">
                                                    <div className='cart-product-info-other-item'>
                                                        <span>{language.unit}: </span>
                                                        <span>{item.unit}</span>
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                        <button className="btn-remove" title="Remove Product" onClick={() => removeFromCarts(item.id, item.unit_id, item.branch_id)}><i className="icon-close"></i></button>
                                    </div>
                                ))}
                            </div>
                            <div className="dropdown-cart-total">
                                <span>{language.total}</span>
                                <span className="cart-total-price">{total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <span>{currency.currency_symbol}</span></span>
                            </div>
                            <div className="dropdown-cart-action">
                                <ALink href="/cart" className="btn btn-outline-primary-2">{language.viewCart}</ALink>
                                <ALink href="/checkout" className="btn btn-primary"><span>{language.checkOut}</span><i className={`icon-long-arrow-${lang == 'ar' ? 'left' : 'right'}`}></i></ALink>
                            </div>
                        </>
                }
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        cartlist: state.cartlist.data
    }
}

export default connect(mapStateToProps, { ...actions })(CartMenu);