import { useRouter } from 'next/router';
import React, { useState, useEffect, useContext } from 'react';
import SlideToggle from 'react-slide-toggle';

import ALink from '~/components/features/alink';
import { LangContext } from '~/context/lang';
import { isMobile } from 'react-device-detect';
import Axios from 'axios';

function MobileMenu() {
    const router = useRouter();
    let { getLang, lang } = useContext(LangContext);
    let language = getLang("header");
    const [searchTerm, setSearchTerm] = useState("");
    const [categories, setCategories] = useState([]);

    let getCategories = async () => {
        await Axios({
            method: 'post',
            responseType: 'json',
            url: 'api/categories/',
            data: {
                lang: lang
            },
        }).then((categories) => {
            setCategories(categories.data['categories']);
        })
    }

    useEffect(() => {
        if (isMobile) {
            getCategories();
        }
    }, [window.location.href]);

    useEffect(() => {
        router.events.on('routeChangeComplete', hideMobileMenu);
    }, [])

    function hideMobileMenu() {
        document.querySelector('body').classList.remove('mmenu-active');
    }

    function onSearchChange(e) {
        setSearchTerm(e.target.value);
    }
    function onSubmitSearchForm(e) {
        e.preventDefault();
        router.push({
            pathname: 'search',
            query: {
                searchKey: searchTerm,
            }
        });
    }

    return (
        <div className="mobile-menu-container">
            <div className="mobile-menu-wrapper">
                <span className="mobile-menu-close" onClick={hideMobileMenu}><i className="icon-close"></i></span>
                <form action="#" method="get" onSubmit={onSubmitSearchForm} className="mobile-search">
                    <input type="text" className="form-control" value={searchTerm} onChange={onSearchChange} name="mobile-search" id="mobile-search" placeholder={language.searchProduct} required />
                    <button className="btn btn-primary" type="submit"><i className="icon-search"></i></button>
                </form>

                <nav className="mobile-nav">
                    <ul className="mobile-menu">
                        <li>
                            <ALink href="/">
                                {language.home}
                            </ALink>
                        </li>
                        {
                            categories.length > 0 && categories.map((category, index) =>
                                <SlideToggle collapsed={true} key={index}>
                                    {({ onToggle, setCollapsibleElement, toggleState }) => (
                                        <li className={toggleState.toLowerCase() == 'expanded' ? 'open' : ''}>
                                            <ALink href={category.subCategories.length <= 0 ? process.env.PUBLIC_URL + 'c/' + category.catUrl : "#0"} className="sf-with-ul">
                                                {category.catName}
                                                {category.subCategories.length > 0 &&
                                                    <span className="mmenu-btn" onClick={(e) => { onToggle(e); e.preventDefault() }}></span>
                                                }
                                            </ALink>
                                            {
                                                category.subCategories.length > 0 &&
                                                <>
                                                    <ul ref={setCollapsibleElement}>
                                                        {

                                                            category.subCategories.map((subcategory, subCatKey) =>
                                                                <SlideToggle collapsed={true} key={subCatKey}>
                                                                    {({ onToggle, setCollapsibleElement, toggleState }) => (
                                                                        <li className={toggleState.toLowerCase() == 'expanded' ? 'open' : ''}>
                                                                            <ALink href={`${process.env.PUBLIC_URL + 'c/' + subcategory.catUrl}`} className="sf-with-ul">{subcategory.catName}
                                                                                {
                                                                                    subcategory.subsub.length > 0 &&
                                                                                    <span className="mmenu-btn" onClick={(e) => { onToggle(e); e.preventDefault() }}></span>
                                                                                }
                                                                            </ALink>
                                                                            {
                                                                                subcategory.subsub.length > 0 &&
                                                                                subcategory.subsub.map((x, y) =>
                                                                                    <ul ref={setCollapsibleElement} key={y} >
                                                                                        <li><ALink href={`${process.env.PUBLIC_URL + 'c/' + x.catUrl}`} style={{ textTransform: "capitalize" }}>{x.catName}</ALink></li>
                                                                                    </ul>
                                                                                )
                                                                            }
                                                                        </li>

                                                                    )}
                                                                </SlideToggle>
                                                            )
                                                        }
                                                    </ul>
                                                </>
                                            }
                                        </li>
                                    )}
                                </SlideToggle>
                            )
                        }

                        <li>
                            <ALink href="/about">
                                {language.aboutUs}
                            </ALink>
                        </li>
                        <li>
                            <ALink href="/contact">
                                {language.contactUs}
                            </ALink>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default React.memo(MobileMenu);