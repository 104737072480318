import React, { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { LangContext } from './lang';

export let AuthContext = React.createContext();

let AuthProvider = ({ children }) => {
    let { getLang, lang } = useContext(LangContext);
    let language = getLang("login");

    let [logged, setLogged] = useState(false);
    let [userData, setUserData] = useState("");
    const [loading, setLoading] = useState(false);
    const [verificationModal, setVerificationModal] = useState(false);
    const [responseAlert, setResponseAlert] = useState({ text: null, type: null });

    let register = async (body) => {
        setLoading(true);
        let registerBtn = document.getElementById('btn-sign-up');
        registerBtn.setAttribute('disabled','disabled');
        let { data } = await axios({
            method: "post",
            url: `${'api/register/'}`,
            responseType: "json",
            data: body
        });
        if (data.status === 1) {
            setResponseAlert({ type: "success", text: data.message });
            window.localStorage.setItem('tempEmail', body.email);
            
            setVerificationModal(true);
        } else {
            setResponseAlert({ type: "danger", text: data.message });
            setLoading(false);
        }
        registerBtn.removeAttribute('disabled');
    };

    let login = async (email, password, redirectUrl) => {
        try {
            setLoading(true);
            let { data } = await axios({
                method: 'post',
                responseType: 'json',
                url: `${'api/login/'}`,
                data: {
                    lang: lang,
                    email,
                    passwd: password,
                    userToken: localStorage.getItem('userToken')
                }
            });
            if (data.status === 1) {
                localStorage.setItem('userData', JSON.stringify(data));
                if (redirectUrl) {
                    window.location.href = redirectUrl;
                } else {
                    window.location.href = '/';
                }
                setInterval(() => {
                    setID(data.id);
                    setUserData(data);
                    setLogged(true);
                }, 500);
            } else if (data.status === 5) {
                setResponseAlert({ type: "warning", text: data.message });
                setLoading(false);
                return data.status;
            } else {
                setResponseAlert({ type: "danger", text: data.message });
                setLoading(false);
            }
        } catch (error) {
            Swal.fire(
                language.warning,
                language.loginError + error,
                'error'
            ).then((resolve) => {
                if (resolve) {
                    setLogged(false);
                }
            });
            setLoading(false);
        }
    };

    let isLogged = async () => {
        try {
            let data = localStorage.getItem('userData');
            if (data) {
                setUserData(data);
                setLogged(true);
            }
        } catch (error) {
            console.log(error);
        }
    };

    let continueWithoutMember = async (uname, email, phone, fullAddress, redirectUrl) => {
        setLoading(true);
        if (phone.length < 13) {
            setResponseAlert({ type: "warning", text: language.phoneError });
            setLoading(false);
        } else {
            let { data } = await axios({
                method: 'post',
                responseType: 'json',
                url: `${'api/login/'}`,
                data: {
                    lang: lang,
                    action: 'withoutRegistration',
                    userName: uname,
                    userEmail: email,
                    userPhone: phone,
                    fullAddress,
                    userToken: localStorage.getItem('userToken')
                }
            });
            if (data.status === 1) {
                localStorage.setItem('userData', JSON.stringify(data));
                if (redirectUrl) {
                    window.location.href = '/guest-buy';
                }
                setInterval(() => {
                    setID(data.id);
                    setUserData(data);
                    setLogged(true);
                }, 500);
            }
        }
    }

    let activeUser = async (code) => {
        if (code) {
            let activeUserBody = {
                code: code,
            }
            let { data } = await axios({
                method: "post",
                url: 'api/login/',
                responseType: "json",
                data: activeUserBody
            });
            if (data.status === 1) {
                setResponseAlert({ type: "success", text: data.message })
            } else {
                setResponseAlert({ type: "danger", text: data.message })
            }
        }
    }

    let logout = async () => {
        setLoading(false);
        Swal.fire({
            title: language.warning,
            icon: 'warning',
            text: language.wantLogout,
            cancelButtonText: language.cancel,
            confirmButtonText: language.yes,
            showCancelButton: true,
        }).then(async (resolve) => {
            if (resolve.isConfirmed === true) {
                localStorage.removeItem('userData');
                localStorage.removeItem('remember');
                setLogged(false);
                window.location.href = '/';

            }
        });
    };

    let directLogout = async (redirect = true) => {
        localStorage.removeItem('userData');
        localStorage.removeItem('remember');
        setLogged(false);
        if (redirect) {
            window.location.href = '/login';
        }
    }



    useEffect(() => {
        isLogged();
    }, []);

    return (
        <AuthContext.Provider
            value={{
                logged,
                register,
                login,
                logout,
                continueWithoutMember,
                directLogout,
                activeUser,
                userData,
                loading,
                responseAlert,
                setResponseAlert,
                verificationModal,
                setVerificationModal
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
