import React, { useContext } from 'react';
import ALink from '~/components/features/alink';
import { AuthContext } from '~/context/auth';
import { getUserData } from '~/utils';

function ProfileMenu({ language, lang }) {
    let { logged, logout } = useContext(AuthContext);
    const user = getUserData();

    return (
        <div className="dropdown profile-dropdown">
            {
                logged ?
                    <>
                        <button className="dropdown-toggle wishlist-link" title={user?.userName}>
                            <div className={`icon position-relative wishlist-icon d-flex align-items-center ${!user.profile_img ? 'p-2' : ''}`}>
                                <img src={"images/icons/my-account.svg"} className='me-2' width={21} style={{ maxWidth: "initial", minWidth: "2.1rem" }} alt="my-account" />
                                <span style={{ whiteSpace: 'nowrap', fontWeight: 600 }}>{user?.userName}</span>
                            </div>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right p-0">
                            <ul className="profile-menu">
                                <ALink href={`/my-account/profile/`}>
                                    <li>
                                        {language.profile}
                                    </li>
                                </ALink>
                                <ALink href={`/my-account/change-password/`}>
                                    <li>
                                        {language.changePassword}
                                    </li>
                                </ALink>
                                <ALink href={`/my-account/my-orders/`}>
                                    <li>
                                        {language.myOrders}
                                    </li>
                                </ALink>
                                <ALink href={`/my-account/my-address/`}>
                                    <li>
                                        {language.myAddress}
                                    </li>
                                </ALink>
                                <ALink href={`/compare/`}>
                                    <li>
                                        {language.compareList}
                                    </li>
                                </ALink>
                                <ALink href={`/my-account/help/`}>
                                    <li>
                                        {language.help}
                                    </li>
                                </ALink>
                                <ALink href={`#`}>
                                    <li onClick={logout}>
                                        {language.signOut}
                                    </li>
                                </ALink>
                            </ul>
                        </div>
                    </>
                    :
                    <>
                        <ALink href="/login" className="wishlist-link" >
                            <div className="wishlist-icon d-flex align-items-center">
                                <img src='/images/icons/user.svg' width={23} style={{ minWidth: "2.3rem" }} alt='user' />
                                <span style={{ whiteSpace: 'nowrap', fontWeight: 600 }}>{language.login}</span>
                            </div>
                        </ALink>
                    </>
            }
        </div>
    )
}

export default ProfileMenu;