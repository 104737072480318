import Axios from "axios";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ALink from "~/components/features/alink";
import { safeContent } from "~/utils";

let SearchMenu = ({ language, lang, currency }) => {
    const router = useRouter();
    const [searchTerm, setSearchTerm] = useState("");
    const [products, setProducts] = useState([]);

    function onSearchChange(e) {
        setSearchTerm(e.target.value);

        if (e.target.value.length >= 2) {
            findProducts(e.target.value);
        } else {
            document.querySelector('.header-search-wrapper').classList.remove('fit');
            setProducts([]);
        }

    }

    function matchEmphasize(name) {
        let regExp = new RegExp(searchTerm, "i");
        return name.replace(
            regExp,
            (match) => "<strong>" + match + "</strong>"
        );
    }

    let findProducts = async (searchKey) => {
        await Axios({
            method: "POST",
            url: "api/suggestion/",
            dataType: "json",
            data: {
                searchKey: searchKey,
                currency,
                lang: lang
            }
        }).then((res) => {
            setProducts(res.data);
            if (res.data.length > 0) {
                document.querySelector('.header-search-wrapper').classList.add('fit');
            }
        })
    }

    function closeSearchForm(e) {
        // if (!e.target.closest('.header-search')) {
        //     document
        //         .querySelector('.header .search-toggle')
        //         .classList.remove('active');
        //     document
        //         .querySelector('.header .header-search')
        //         .classList.remove('show');
        //     setProducts([]);
        //     document.querySelector('input[name="searchKey"]').value = '';
        // } else {
        //     document
        //         .querySelector('.header-search-wrapper')
        //         .classList.remove('fit');

        //     setProducts([]);
        // }
    }

    useEffect(() => {
        document.querySelector("body").addEventListener('click', closeSearchForm);

        return (() => {
            document.querySelector("body").removeEventListener('click', closeSearchForm);
        })
    }, []);

    function onSubmitSearchForm(e) {
        e.preventDefault();
        router.push({
            pathname: '/search',
            query: {
                searchKey: searchTerm
            }
        });
        closeSearchForm(e);
    }
    return (
        <>
            <div style={{ width: '100%' }}>
                <form action="#" method="get" onSubmit={onSubmitSearchForm}>
                    <div className="header-search">
                        <div className="header-search-wrapper">
                            <div>
                                <input type="text" onKeyUp={onSearchChange} className="form-control mb-0" name="searchKey" placeholder={language.searchProduct} required />
                                <i className="icon-search"></i>
                            </div>
                            <div className="live-search-list" >
                                {
                                    products.length > 0 ?
                                        products.map((product, index) =>
                                            <a href={`/product/${product.productUrl}`} className="autocomplete-suggestion" key={`search-result-${index}`} onClick={closeSearchForm}>
                                                <div className='overflow-hidden'>
                                                    <LazyLoadImage src={process.env.IMG_URL + 'products/' + product.productImage} width={40} height={40} alt="product" />
                                                </div>
                                                <div className="search-name" dangerouslySetInnerHTML={safeContent(matchEmphasize(product.productName + (Number.isInteger(Number(searchTerm)) ? ' <small>(' + language.variationNo + ' ' + searchTerm + ')</small>' : '')))}></div>
                                                <span className="search-price">
                                                    {
                                                        <div className="product-price mb-0"> {language.sar} {product.productPrice.toFixed(2)}</div>
                                                    }
                                                </span>
                                            </a>
                                        )
                                        :
                                        <div className="autocomplete-suggestion" key={`search-result`} onClick={closeSearchForm}>
                                            <div className='overflow-hidden'>
                                            </div>
                                            <div className="search-name">{language.noResult}</div>
                                            <span className="search-price">
                                            </span>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
export default SearchMenu;