import { combineReducers, legacy_createStore, applyMiddleware } from 'redux';
import { createWrapper } from 'next-redux-wrapper';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './root-saga';
import { persistStore } from 'redux-persist';

// Import Reducers
import cartReducer from "./cart";
import wishlistReducer from './wishlist';
import compareReducer from './compare';
import otherReducer from './other';

const rootReducers = combineReducers({
    cartlist: cartReducer,
    wishlist: wishlistReducer,
    comparelist: compareReducer,
    other: otherReducer,
});

const sagaMiddleware = createSagaMiddleware();

export const makeStore = () => {
    const store = legacy_createStore(rootReducers, applyMiddleware(sagaMiddleware));
    store.sagaTask = sagaMiddleware.run(rootSaga);
    store.__persistor = persistStore(store);
    return store;
};

// export default makeStore;

export const wrapper = createWrapper(makeStore);