import React, { useState, useEffect } from 'react';
import Fancybox from "~/components/features/fancybox";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function GalleryDefault(props) {
    const { product, adClass = "", language } = props;
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);

    useEffect(() => {
        setNav1(slider1);
        setNav2(slider2);

    });
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    var thumbnailSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: product.productImgs.length,
        slidesToScroll: 1,
        swipeToSlide: false,
        focusOnSelect: true,
        vertical: true
    };

    if (!product) {
        return <div></div>
    }

    return (
        <>
            <div className={`product-gallery ${adClass}`}>
                <div className="row m-0">
                    <div className="col-lg-2">
                        <div id="product-zoom-gallery" className='product-slider-thumbnails'>
                            <Slider {...thumbnailSettings}
                                asNavFor={nav1}
                                ref={slider => (setSlider2(slider))}>
                                {
                                    product.productImgs.length > 0 ?
                                        product.productImgs.map((item, index) =>
                                            <button className={`${0 === index ? 'active' : ''}`} style={{ padding: 0 }} key={product.id + '-' + index}>
                                                <img src={process.env.IMG_URL + 'products/small_' + item.imgFile} width={100} height={100} alt="product back" />
                                            </button>
                                        )
                                        :
                                        <img src={process.env.IMG_URL + 'products/noImage.webp'} width={100} height={100} alt="product" />
                                }
                            </Slider>
                        </div>
                    </div>
                    <div className="col-lg-10 g-0">
                        <div className="product-main-image">
                            {
                                product.new &&
                                <span className="product-label label-new">{language.new}</span>
                            }
                            {
                                product.discount_price > 0 &&
                                <span className="product-label label-sale">{language.sale}</span>
                            }
                            {
                                !product.stock || product.stock == 0 &&
                                <span className="product-label label-out">{language.outOfStock}</span>
                            }
                            {
                                product.productImgs.length > 0 ?
                                    <Fancybox options={{ infinite: false }}>
                                        <Slider {...settings}
                                            asNavFor={nav2}
                                            ref={slider => (setSlider1(slider))}
                                        >
                                            {
                                                product.productImgs.map((item, index) =>
                                                    <div key={index}>
                                                        <a href={process.env.IMG_URL + 'products/' + item.imgFile}
                                                            key={'product-gallery-' + index}
                                                            data-fancybox="gallery"
                                                            data-src={process.env.IMG_URL + 'products/' + item.imgFile}
                                                            rel="noreferrer">
                                                            <img key={index} src={process.env.IMG_URL + 'products/' + item.imgFile} alt={"product"} />
                                                        </a>
                                                    </div>
                                                )
                                            }
                                        </Slider>
                                    </Fancybox>
                                    :
                                    <img src={process.env.IMG_URL + 'products/noImage.webp'} width={500} height={550} alt="product" />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(GalleryDefault);