import Link from "next/link";

export default function ALink({ children, className, style, target = "_self", ...props }) {
    function defaultFunction(e) {
        if (props.href == '#') {
            e.preventDefault();
        }
    }

    return (
        <Link {...props} className={className} style={style} onClick={defaultFunction} target={target} scroll={false} referrerPolicy={"no-referrer"}>
            {children}
        </Link>
    )
}